var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.level)?_c('details',{staticClass:"item-header level-even",attrs:{"open":""}},[_c('summary',{staticClass:"leaf text-body-2"},[_c('span',{staticClass:"item-name"},[_vm._v("Categories and Codes")]),_c('v-chip',{staticClass:"item-files mr-1",attrs:{"x-small":"","color":"secondary","outlined":""}},[_c('v-icon',{staticClass:"icon-files",attrs:{"left":"","x-small":""}},[_vm._v("$description")]),_vm._v(" files ")],1),_c('v-chip',{staticClass:"item-marked",attrs:{"x-small":"","color":"primary","outlined":""}},[_c('v-icon',{staticClass:"icon-marked",attrs:{"left":"","x-small":""}},[_vm._v("$bookmark")]),_vm._v(" marked ")],1)],1)]):_vm._e(),_vm._l((_vm.items),function(item,index){return _c('details',{key:item.id,class:{
			last: index + 1 === _vm.items.length,
			first: index === 0,
			'level-odd': _vm.level % 2,
			'level-even': !(_vm.level % 2)
		},attrs:{"open":"","data-level":_vm.level}},[_c('summary',{staticClass:"flex text-body-2",class:{
				leaf: !(item.children && item.children.length)
			}},[_c('v-icon',{staticClass:"icon-close",attrs:{"small":""}},[_vm._v("add_circle")]),_c('v-icon',{staticClass:"icon-open",attrs:{"small":""}},[_vm._v("remove_circle")]),_c('span',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))]),_c('v-chip',{staticClass:"item-files mr-1",attrs:{"x-small":"","color":"secondary","outlined":""}},[_c('v-icon',{staticClass:"icon-files",attrs:{"left":"","x-small":""}},[_vm._v("$description")]),_vm._v(" "+_vm._s(item.files)+" ")],1),_c('v-chip',{staticClass:"item-marked",attrs:{"x-small":"","color":"primary","outlined":""}},[_c('v-icon',{staticClass:"icon-marked",attrs:{"left":"","x-small":""}},[_vm._v("$bookmark")]),_vm._v(" "+_vm._s(item.marked)+" ")],1)],1),(item.children && item.children.length)?_c('p',{staticClass:"ma-0 pl-6"},[_c('oqda-tree-view',{attrs:{"items":item.children,"level":_vm.level ? _vm.level + 1 : 1}})],1):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }