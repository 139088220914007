











import { mapGetters } from 'vuex';
import OqdaTreeView from '@/components/oqda-tree-view.vue';

export default {
	name: 'category-tree',
	components: { OqdaTreeView },
	computed: mapGetters(['getCategoryTree']),
	methods: {
		printPage: () => {
			window.print();
			return false;
		}
	}
};
