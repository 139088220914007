






















































import Vue from 'vue';

export default Vue.extend({
	name: 'OqdaTreeView',
	props: ['items', 'level']
});
